import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import {
  GetAreaCoordinators_resourcesByEntityId_results as CoordinatorListItem,
  GetAreaCoordinators_resourcesByEntityId_results_roles,
} from 'src/graphql-types/GetAreaCoordinators';
import { comp_LeagueStatus, RoleState, SafePlayStatus } from 'src/graphql-types/globalUstaTypes';
import { formatDate } from 'src/utils/helper/membership';

interface ShowSafePlayStatusProps {
  coordinator: CoordinatorListItem;
}
export const ShowSafePlayStatus: React.FC<ShowSafePlayStatusProps> = ({ coordinator }) => {
  const { t } = useTranslation();
  const status = coordinator?.person?.extensions?.find((ext) => ext.name === 'safePlayStatus');
  const statusExpiry = coordinator?.person?.extensions?.find((ext) => ext.name === 'safePlayExpiryDate');

  const statusVarietyMap = {
    [SafePlayStatus.APPROVED]: t('safe play approved', {
      date: new Date(statusExpiry?.value),
    }),
    [SafePlayStatus.EXPIRED]: t('safe play expired', {
      date: new Date(statusExpiry?.value),
    }),
    [SafePlayStatus.NOTAPPROVED]: t('not approved'),
    [SafePlayStatus.PENDING]: t('pending'),
    [SafePlayStatus.NOTSET]: t('not set'),
    [SafePlayStatus.NOTSTARTED]: t('not started'),
  };
  return <>{status ? statusVarietyMap[status.value] : ''}</>;
};

interface ShowInviteStatusProps {
  states: GetAreaCoordinators_resourcesByEntityId_results_roles[] | null;
}
type StateVarietyRecord = { variety: LabelVariety; label: string };
export const ShowInviteStatus: React.FC<ShowInviteStatusProps> = ({ states }) => {
  const { t } = useTranslation();
  const stateVarietyMap: Record<RoleState, StateVarietyRecord> = {
    [RoleState.ACTIVE]: { variety: 'success', label: 'registered' },
    [RoleState.PENDING]: { variety: 'warning', label: 'invited' },
    [RoleState.EXPIRED]: { variety: 'neutral2', label: 'expired' },
    [RoleState.REVOKED]: { variety: 'warning', label: 'revoked' },
  };

  return (
    <>
      {states?.map((state) => {
        if (state.role === 'teamcoordinator' && state.state in stateVarietyMap) {
          const { variety, label } = stateVarietyMap[state.state];
          return (
            <>
              <StatusLabel key={`${label}-${variety}`} variety={variety}>
                {t(label)}
              </StatusLabel>
              {state.stateUpdatedAt && `(${formatDate(state.stateUpdatedAt, 'MM/DD/YYYY')})`}
            </>
          );
        }
        return null;
      })}
    </>
  );
};
export const isInviteStateActive = (roles: GetAreaCoordinators_resourcesByEntityId_results_roles[]) =>
  !!roles.find((role) => role.role === 'teamcoordinator' && role.state === RoleState.ACTIVE);

export const leagueStatusVarietyMap: Record<comp_LeagueStatus, StateVarietyRecord> = {
  [comp_LeagueStatus.REGISTRATIONS_CLOSED]: {
    variety: 'error',
    label: 'registration close',
  },
  [comp_LeagueStatus.REGISTRATIONS_OPEN]: {
    variety: 'neutral2',
    label: 'registration open',
  },
  [comp_LeagueStatus.IN_PROGRESS]: {
    variety: 'neutral2',
    label: 'in progress',
  },
  [comp_LeagueStatus.APPROVED]: { variety: 'success', label: 'approved' },
  [comp_LeagueStatus.PUBLISHED]: { variety: 'neutral2', label: 'published' },
  [comp_LeagueStatus.COMPLETED]: { variety: 'success', label: 'completed' },
  [comp_LeagueStatus.PLANNING]: { variety: 'neutral2', label: 'planning' },
};
export type LeagueStatusProps = {
  status: comp_LeagueStatus;
};
export const LeaguesStatus: React.FC<LeagueStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  const { variety, label } = leagueStatusVarietyMap[status];

  return (
    <StatusLabel key={`${label}-${variety}`} variety={variety}>
      {t(label)}
    </StatusLabel>
  );
};

interface IAreaModal<T> {
  show: boolean;
  parentLevelGroupId?: string;
  areaToEdit?: T;
  parentAreaId?: string;
}

export const useAreaModal = <T,>() => {
  const [areaModal, setAreaModal] = useState<IAreaModal<T>>({
    show: false,
  });

  const openAreaModal = useCallback((payload?: Omit<IAreaModal<T>, 'show'>) => {
    setAreaModal({
      show: true,
      ...payload,
    });
  }, []);

  const closeAreaModal = useCallback(() => {
    setAreaModal({ show: false });
  }, []);

  return { openAreaModal, closeAreaModal, areaModal };
};
