import React, { useEffect, useMemo, useState } from 'react';

import { meshGatewayClient } from 'src/apollo/client';
import Panel from 'src/components/panel/panel';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { SectionDistrictEmptyFilter } from 'src/components/tournament-filters/tournament-filters';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { GetLeagues, GetLeaguesVariables } from 'src/graphql-types/GetLeagues';
import { useDefaultSectionAndDistrict } from 'src/utils/helper/useDefaultSectionAndDistrict';

import LeaguesFilters, { AvailableFilters } from './leagues-filters';
import { GET_LEAGUES } from './leagues-queries';
import LeaguesTable from './leagues-table/leagues-table';

export interface LeaguesPaginationProps extends GetLeaguesVariables {
  offset: number;
  limit: number;
}
const LeaguesListing = () => {
  const [section, setSection] = useState<string>();
  const [district, setDistrict] = useState<string>();
  const [seasonId, setSeasonId] = useState<string>();

  const filters = useMemo(() => {
    let filters: Record<any, any> = {};
    if (section && section !== SectionDistrictEmptyFilter.ALL) {
      filters = { ...filters, parentOrganisationId: section };
    }
    if (district && district !== SectionDistrictEmptyFilter.ALL) {
      filters = { ...filters, parentOrganisationId: district };
    }

    if (seasonId) {
      filters = { ...filters, seasonId };
    }
    return filters;
  }, [district, seasonId, section]);

  const defaultSectionDistrict = useDefaultSectionAndDistrict();

  const {
    data: areaLeagues,
    loading,
    controlProps,
  } = useControlledQuery<GetLeagues, LeaguesPaginationProps>(GET_LEAGUES, {
    client: meshGatewayClient,
    getTotalItems: (area: GetLeagues) => area.leagues.totalItems ?? 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !filters.seasonId,
    transformVariables: (v: LeaguesPaginationProps) => {
      const { offset, limit } = v;

      return {
        ...(Object.keys(filters).length > 0 && { filter: filters }),
        pagination: { limit, skip: offset },
      };
    },
  });

  useEffect(() => {
    if (defaultSectionDistrict) {
      const { defaultDistrict, defaultSection } = defaultSectionDistrict;
      setSection(defaultSection);
      setDistrict(defaultDistrict);
    }
  }, [defaultSectionDistrict]);

  return (
    <PageMaxWidth>
      <Panel>
        <LeaguesFilters
          excludedFilters={[AvailableFilters.SEARCH, AvailableFilters.CATEGORY, AvailableFilters.STATUS]}
          onFilterChange={(filters) => {
            setSeasonId(filters.seasonId);
          }}
        />
      </Panel>
      <Panel extendedPadding>
        <LeaguesTable leagues={areaLeagues?.leagues?.items} controlProps={controlProps} loading={loading} />
      </Panel>
    </PageMaxWidth>
  );
};

export default LeaguesListing;
